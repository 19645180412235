import { PriceDetailPosition, ProductInterface } from '@bytel/product-wall/types';

import { getFundingOrder, getFundingPhone } from '@services/products';

import { SapiPlanType } from '@app-types/api/sapi';
import { ContractType } from '@app-types/contract';
import { DiscountEnumType, DiscountType } from '@app-types/discount';
import { FundingType } from '@app-types/funding';
import { PlanDetailWithProvenanceType, PlanProvenanceType, PlanType } from '@app-types/plan';
import { Product, ProductChildFull, ProductType } from '@app-types/product';

import { paths } from '@helpers/path';

import { PlanTypeId } from '@constants/plan';

import { LockedPlanValue } from '@home-mobile/helpers';

const createProductDisplay = (
    product: Product,
    plan?: PlanDetailWithProvenanceType,
    contract?: ContractType,
): Product => {
    if (plan) {
        const isFunding = product.priceFrom;
        const isPlanFromUrl = plan.origin === PlanProvenanceType.URL;
        const isPlanFromCart = plan.origin === PlanProvenanceType.CART;
        const isPremiumRenewal = contract?.subscription.isPremium ?? false;

        const newProduct: Product = { ...product };

        newProduct.priceFrom = !isPlanFromUrl && ((!isPremiumRenewal && !isPlanFromCart) || isFunding);

        if (!product.priceDetail && newProduct.priceFrom) {
            newProduct.priceDetail = {
                position: PriceDetailPosition.BESIDE,
            };
        }

        return newProduct;
    }
    return product;
};

export const getDisplayProducts = (
    products: Product[],
    plan?: PlanDetailWithProvenanceType,
    contract?: ContractType,
): Product[] => {
    return products.map((product) => createProductDisplay(product, plan, contract));
};

export const findProduct = (products: Product[], id: ProductInterface['id']): Product | undefined => {
    return products.find((product) => product.id === id);
};

export const getProductUrlByType = (slug: string, type: ProductType, isRenewal: boolean): string => {
    switch (type) {
        case ProductType.PHONE:
        case ProductType.PHONE_SIMPLE:
            return paths.phoneDetail(slug, isRenewal);
        case ProductType.ACCESSORY:
        case ProductType.ACCESSORY_CONFIGURABLE:
            return paths.accessoryDetail(slug);
        default:
            return `/${slug}`;
    }
};

export const getSimilarProductsLink = ({ type, manufacturerCode }: ProductChildFull, isRenewal: boolean) => {
    if (type === ProductType.PHONE || type === ProductType.PHONE_SIMPLE) {
        return paths.phoneFilter(isRenewal, manufacturerCode);
    }

    return type === ProductType.ACCESSORY ? paths.accessoryWall : '';
};

export const mapApiPlanToPlanType = (planSapi: SapiPlanType, planSapic?: PlanType, phoneGencode?: string): PlanType => {
    let fundings: FundingType[] = [];
    if (phoneGencode && planSapic) {
        fundings = getFundingPhone(planSapic, phoneGencode)?.sort(
            (a: FundingType, b: FundingType) => getFundingOrder(a.type) - getFundingOrder(b.type),
        );
    }

    const basePlan: Partial<PlanType> = planSapic ? planSapic : {};

    // @TODO: no mapping here - use repo / mapper
    const detailData: Partial<PlanType> = {
        image: '',
        recommended: false,
        inStock: true,
        gencode: planSapi.gencode,
        sku: planSapi?.sku,
        name: planSapic?.name ?? planSapi.name,
        typeId: planSapi.type_id as PlanTypeId,
        dataEnvelope: planSapi.data_envelope || '',
        dataSize: planSapi.data_size,
        hasLowPrice: planSapi.low_prices_wall,
        hasDoubleSim: planSapi.double_sim,
        isDarwin: planSapi.is_darwin,
        locked: planSapi.locked as LockedPlanValue,
        smsMms: planSapi.sms_mms,
        internationalCall: planSapi.international_call,
        fromAbroad: planSapi.from_abroad,
        price: planSapi.price,
        maxConnections: planSapi.max_connection,
        detailsDescription: planSapi.catchy_description,
        fundings,
        urlKey: planSapi.url_key,
        commercialRange: planSapi.commercial_range,
        range: planSapi.commercial_range,
        entityId: planSapi.entity_id,
        planFlag: planSapi.plan_flag,
    };

    return { ...basePlan, ...detailData } as PlanType;
};

export const isPlanPremium = (planType: PlanTypeId): boolean => {
    return [PlanTypeId.PREMIUM, PlanTypeId.PREMIUM_PRO, PlanTypeId.PREMIUM_RENEWAL].includes(planType);
};

export const getDiscountValue = (discounts: DiscountType[], type: DiscountEnumType): number => {
    return discounts.reduce((acc, discount) => {
        return discount.type === type ? acc + discount.value : acc;
    }, 0);
};

export const getProductNameByGencode = (gencode: string, products: PlanType[]): string | undefined => {
    const product = products.find((plan) => plan.gencode === gencode);
    return product?.name;
};

export function getProductName(brand?: string, name: string = '') {
    return brand ? `${brand} ${name}` : name;
}
