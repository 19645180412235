import { httpService } from '@services/http';
import { HttpService } from '@services/http/http';

import { skeepersRepository } from '@repositories/skeepers';

import { ReviewAverageType, ReviewsInfoType } from '@app-types/review';

import { isServer } from '@helpers/config';

export class ReviewsServices {
    private httpService: HttpService;

    public constructor(httpService: HttpService) {
        this.httpService = httpService;
    }

    public async getAverageReviews(gencodes: string[]): Promise<ReviewAverageType[] | undefined> {
        if (isServer()) {
            return this.getAverageReviewsServer(gencodes);
        }

        return this.httpService
            .post<ReviewAverageType[] | undefined>('/webapi/reviews', {
                gencodes,
            })
            .catch(() => undefined);
    }

    private async getAverageReviewsServer(gencodes: string[]): Promise<ReviewAverageType[] | undefined> {
        return skeepersRepository.getAverageReviews(gencodes);
    }

    public async getReviews(
        gencode: string,
        offset: number = 0,
        limit: number = 5,
    ): Promise<ReviewsInfoType | undefined> {
        if (isServer()) {
            return this.getReviewsServer(gencode, offset, limit);
        }

        return this.httpService
            .get<ReviewsInfoType | undefined>('/webapi/reviews', {
                gencode,
                offset: offset.toString(),
                limit: limit.toString(),
            })
            .catch(() => undefined);
    }

    private async getReviewsServer(
        gencode: string,
        offset: number = 0,
        limit: number = 5,
    ): Promise<ReviewsInfoType | undefined> {
        return skeepersRepository.getReviews(gencode, offset, limit);
    }
}

export const reviewsServices = new ReviewsServices(httpService);
