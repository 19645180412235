import type MagicartType from '@bytel/magicart';
import { Product as MigcartProduct } from '@bytel/magicart/dist/src/catalog';

import { getAppConfig } from '@services/config';
import { getPlanDetail } from '@services/products';

import { AddressType } from '@app-types/address';
import { StorageAddressType, StorageQuoteType } from '@app-types/api/cart';
import { QUOTE_TYPES, QuoteType } from '@app-types/cart';
import { PlanType } from '@app-types/plan';
import { Product, ProductType, SimulatedCartProduct } from '@app-types/product';
import { SimulatorCart } from '@app-types/simulator/virtual-cart';
import { UserType } from '@app-types/user';

import { API_X_VERSION } from '@constants/api';

function addressMapper(address: StorageAddressType): AddressType {
    return {
        zip: address.codePostal,
        city: address.ville,
        street: address.rue,
        country: address.pays,
        streetNumber: address.numeroRue,
        addressComplement: address.complementAdresse,
        residence: address.residence,
        building: address.batiment,
        staircase: address.escalier,
        floor: address.etage,
        coordinates: address.coordonnees,
        isGcpValidated: address.isGcpValidated,
        isWaitingForConfirmation: address.isWaitingForConfirmation,
    };
}

export function quoteMapper(quote: StorageQuoteType): QuoteType {
    return {
        ...quote,
        address: {
            billing: quote.address?.billing ? addressMapper(quote.address.billing) : null,
            shipping: quote.address?.shipping ? addressMapper(quote.address.shipping) : null,
        },
    };
}

export async function getPlanFromCart(quote: QuoteType): Promise<PlanType | undefined> {
    const gencode = quote.products.find(
        (product) => product.isPrincipal && product.productLine === 'Forfaits_mobile',
    )?.gencode;
    if (!gencode) {
        return undefined;
    }
    return getPlanDetail(gencode);
}

export async function getCart(user?: UserType, contractId?: string, isPro = false): Promise<MagicartType> {
    const Magicart = (await import('@bytel/magicart')).default;
    const { ENV_LIST } = await import('@bytel/magicart');
    const appConfig = getAppConfig();

    return new Magicart(
        {
            xSource: 'portailvente_web',
            salesApiVersion: API_X_VERSION.V4,
            cartExpiration: 48,
            yama: {
                referrers: [],
            },
            partner: {},
            environment: ENV_LIST[appConfig.cart],
            resourceUrl: appConfig.sapi.url,
            xBanc: appConfig.bench,
        },
        undefined,
        user
            ? {
                  id: parseInt(user.sub),
                  name: user.name,
                  accessToken: user.accessToken,
                  isPro,
                  isCdc: user.user_type === 'CDC',
              }
            : undefined,
        contractId,
    );
}

export function createFaiVirtualCart(faiPlan: string[], mobilePlan?: string[]): SimulatorCart {
    const virtualCart: SimulatorCart = {
        cart: {
            quotes: [
                {
                    type: QUOTE_TYPES.ACQUISITION_FIXE,
                    isCurrent: true,
                    products: faiPlan.map((gencode) => ({ gencode })),
                },
            ],
        },
    };

    if (mobilePlan?.length) {
        virtualCart.cart.quotes.push({
            type: QUOTE_TYPES.ACQUISITION,
            isCurrent: false,
            products: mobilePlan.map((gencode) => ({ gencode })),
        });
    }

    return virtualCart;
}

export function getCurrentPhone(cart: SimulatedCartProduct | undefined): Product | undefined {
    const currentQuote = cart?.quotes.find((quote) => quote.current);
    if (currentQuote) {
        const phone = currentQuote.products.find((product) => product.type === ProductType.PHONE_SIMPLE);

        if (phone) {
            return phone;
        }
    }
    return undefined;
}

export function getCartPlans(cart: MagicartType): MigcartProduct[] {
    return [...cart.getAllProductsByType('Sowo'), ...cart.getAllProductsByType('Sensation')];
}

export async function getCurrentPhoneInCart(): Promise<MigcartProduct | null> {
    const cartService = await getCart();
    const phone = cartService.getAllProductsByType('Phone');
    return phone[0] || null;
}
